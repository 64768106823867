import React from "react";

import basquiatLogo from "../../logos/basquiat.jpeg";
import konturLogo from "../../logos/kontur.png";
import fortechLogo from "../../logos/fortech.jpg";
import fortech from "../../logos/fortech.svg";
import basquiat from "../../logos/basquiat.svg";

import "./footer.css";

export const Footer = () => {
  return (
    <div>
      <div className="footer desctop">
        <div className="footer_text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </div>
        <div className="logos">
          <img src={basquiatLogo} className="logo circle" alt="" />
          <img src={konturLogo} className="logo circle" alt="" />
          <img src={fortechLogo} className="fortech_logo" alt="" />
        </div>
      </div>
      <div className="footer mobile">
        <img src={basquiat} alt="basquiat" />
        <img src={fortech} alt="fortech" />
      </div>
    </div>
  );
};
