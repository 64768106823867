import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import axios from "axios";

import config from "./components/Loader/api";

import walletLogo from "./logos/apple_wallet.png";
import telegramLogo from "./logos/telegram.svg";
import hand from "./logos/hand-left.svg";
import mainPage from "./logos/main-page-img.svg";

import "./App.css";

import { Loader } from "./components/Loader";
import { Footer } from "./components/Footer/Footer";
import { AppHeader } from "./components/Header/header";

function App() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [walletSrc, setWalletSrc] = useState("");
  const [searchParams] = useSearchParams();
  const search = searchParams.get("search");
  const requestCode = searchParams.get("requestCode");

  const submit = async () => {
    setIsLoading(true);
    try {
      const resp = await axios.get(
        `${config.apiUrl}/archive?search=${search}&requestCode=${requestCode}`,
        { responseType: "blob" }
      );
      setWalletSrc(window.URL.createObjectURL(resp.data));
    } catch (err: any) {
      let error = "";
      switch (err?.response?.status) {
        case 500:
          error = "Что то пошло не так";
          break;
        case 404:
          error = "Неверный код или номер телефона";
          break;
        case 400:
          error = "Неверный формат";
          break;
        case 401:
          error = "Неверный код";
          break;
      }
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (search && requestCode) {
      submit();
    }
  }, [search, requestCode]);

  return (
    <div className="app">
    <AppHeader />
      <div className="body">
        <div className="help_text">
          <span className="desctop">
            Чтобы получить визитку обратитесь к нашему боту{" "}
          </span>
          <a
            href={process.env.REACT_APP_BOT_LINK}
            className="telegram_link desctop"
          >
            в Telegram
          </a>
          <span className="mobile">
            Чтобы получить карту лояльности, обратитесь к нашему боту <br />
          </span>
          <div className="help_text-action mobile">
            <span className="action-text">
              в
            </span>
            <div
              className="mobile link-mobile"
            >
              <a
                href={process.env.REACT_APP_BOT_LINK}
                className="phone-link"
              >
                Telegram
              </a>
            </div>
            <img className="mobile" src={hand} alt="" />
          </div>
          <a
            href={process.env.REACT_APP_BOT_LINK}
            className="telegram_link desctop"
          >
            <img alt="telegram" src={telegramLogo} />
          </a>
        </div>
        <img className="main-page-img" alt="main-page" src={mainPage}></img>
      </div>
      {error && <div className="error_message">{error}</div>}
      {walletSrc && (
        <a href={walletSrc} download="pass.pkpass" className="link">
          <img src={walletLogo} />
          <div>Нажмите чтобы скачать</div>
        </a>
      )}
      <Loader isOpen={isLoading} />
      <Footer />
    </div>
  );
}

export default App;
