import React from "react";
import { useNavigate } from "react-router-dom";

import back from "../../logos/back.svg";

import "./header.css";

export const AppHeader = () => {
  const naviagte = useNavigate();
  function goPrevious() {
    naviagte(-1);
  }
  return (
    <div className="header">
      <span className="desctop">Басския Wallet</span>
      <button onClick={goPrevious} className="header-previous">
        <img src={back} alt="go back" />
      </button>
    </div>
  );
};
