import React from "react";

import "./card.css";

import walletAdd from "../../logos/wallet_add.svg";

import { Footer } from "../Footer/Footer";
import { AppHeader } from "../Header/header";

export const Card = () => {
  return (
    <div className="page-wrapper">
      <div className="card-page" />
      <div className="header-wrapper">
        <AppHeader />
      </div>
      <main>
        <div className="card-info-main light">
          <div className="card-info">
            <span className="card-title">
              Карта готова! <br />
              Вы можете добавить ее в кошелек
            </span>
            <img
              className="card-action"
              src={walletAdd}
              alt="добавить изображение"
            />
          </div>
        </div>
        <div className="footer-wrapper">
          <Footer />
        </div>
      </main>
    </div>
  );
};
