import React from "react";

import "./index.css";

interface LoaderProps {
  isOpen: boolean;
}

export const Loader = ({ isOpen }: LoaderProps) =>
  isOpen ? (
    <div className="loader_container">
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : (
    <></>
  );
