import React from "react";
// import * as ReactDOM from "react-dom/client";
import ReactDOM from "react-dom";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./index.css";

import App from "./App";
import { Card } from "./components/CardPage/card";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/card",
    element: <Card />,
  },
]);

const container = document.getElementById("root") as HTMLElement;
ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  container
);
